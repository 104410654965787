import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './sass/main.scss'
import { Modal } from 'antd';
import { StepSection } from './components/molecules/StepSection/StepSection';
import { PageLayout, PageContent } from './components/molecules/PageLayout/PageLayout';
import { BubbleNavigator, BubbleNavigatorContainer } from './components/atoms/BubbleNavigator/BubbleNavigator';
import { ONBOARDING_STEPS, ONBOARDING_STEP_CREDIT_SCORE_KEY, ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY, ONBOARDING_STEP_LOAN_LENGHT_KEY, ONBOARDING_STEP_LOAN_PURPOSE_KEY, ONBOARDING_STEP_PROPERTY_CONDITION_KEY, ONBOARDING_STEP_PROPERTY_KIND_KEY, ONBOARDING_STEP_STATE_KEY, getOnboardingStep, updateRentalOptions } from './utils/constants';
import { StepBar } from './components/molecules/StepBar/StepBar';
import { ResumeFooter } from './components/molecules/ResumeFooter/ResumeFooter';
import { Resume } from './components/molecules/Resume/Resume';
import { SendLoanForm } from './components/molecules/SendLoanForm/SendLoanForm';
import { LoanBuilder } from './components/molecules/LoanBuilder/LoanBuilder';
import { PreApprovalForm } from './components/molecules/PreApprovalForm/PreApprovalForm';
import { LoanResume } from './components/molecules/LoanResume/LoanResume';
import { WelcomePage } from './components/molecules/WelcomePage/WelcomePage';
import { BorrowerForm } from './components/molecules/BorrowerForm/BorrowerForm';
import { CreateAccountBroker } from './components/molecules/CreateAccountBroker/CreateAccountBroker';
import { ProcessingTeamForm } from './components/molecules/ProcessingTeamForm/ProcessingTeamForm';
import { makeVar, useReactiveVar } from '@apollo/client';
import { isMixedUseProperty as getIsMixedUseProperty, isLongTermRental } from './utils/functions';
import { trackEvent, QuestionAnsweredFromPage, OptionSelected, trackSectionLoadedEvent } from './utils/mixpanel';
import isEqual from 'lodash.isequal';
import { useExchangeOAuthToken } from './services/oauth';

const PROCESSING_TEAM_INITIAL_VALUES = (
  ONBOARDING_STEPS.reduce((acc, step) => {
    const value = (
      (step.text === ONBOARDING_STEPS[5].text) ?
        step.options[step.options.length - 1].text
        :
        step.options.find(option => !option.isDisabled).text
      
    )
    return {
      ...acc,
      [step.text]: value
    }
  }, {})
)

const SAMPLE_VALUES = (
  {
    "Where is the property located?": getOnboardingStep(ONBOARDING_STEP_STATE_KEY).options[4].text,
    "What condition is the property in?": getOnboardingStep(ONBOARDING_STEP_PROPERTY_CONDITION_KEY).options[0].text,
    "What is your ideal loan length?": getOnboardingStep(ONBOARDING_STEP_LOAN_LENGHT_KEY).options[1].text,
    "Do you currently own this property?": getOnboardingStep(ONBOARDING_STEP_LOAN_PURPOSE_KEY).options[0].text,
    "What kind of property is it?": getOnboardingStep(ONBOARDING_STEP_PROPERTY_KIND_KEY).options[0].text,
    "What’s your credit score?": getOnboardingStep(ONBOARDING_STEP_CREDIT_SCORE_KEY).options[5].text,
    "How many properties have you purchased/sold in the last 24 months?": getOnboardingStep(ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY).options[2].text
  }
)

const DEFAULT_VALUES_VALUE = (
  // SAMPLE_VALUES
  // ||
  {}
)

/**
 * Sends all the Onboarding collected data to the BE
 * 
 * @param {object} values The answers to the questions that were made in the background 
 * @param {object} loanData The request/response that was sent to the loan pricer
 * @param {object} userData The user data 
 */
const onFinish = (values, loanData, userData) => {

}

function App() {
  /* Application data */
  const [values, setValues] = useState({ ...DEFAULT_VALUES_VALUE })
  const [userData, setUserData] = useState(null)

  const setValue = (key, value) => {
    let newValues;

    // If repair
    if (key === ONBOARDING_STEPS[1].text) {
      if (value === ONBOARDING_STEPS[1].options[1].text) {
        // Disable the long term
        ONBOARDING_STEPS[2].options[1].isDisabled = true
        // And auto select the short term
        newValues = {
          ...values,
          [key]: value,
          [ONBOARDING_STEPS[2].text]: ONBOARDING_STEPS[2].options[0].text
        }
      } else {
        ONBOARDING_STEPS[2].options[1].isDisabled = false
        newValues = {
          ...values,
          [key]: value,
        }
        if (values[ONBOARDING_STEPS[2].text]) (
          newValues = {
            ...newValues,
            [ONBOARDING_STEPS[2].text]: ONBOARDING_STEPS[2].options[0].text
          }
        )
      }
    } else {
      newValues = { ...values, [key]: value } 
    }

    if (newValues["What is your ideal loan length?"] === "Long-term (30 years)") {
      newValues = {
        ...newValues,
        ["What condition is the property in?"]: "It’s stabilized and/or ready to rent"
      }
    } 

    setValues(newValues)
  }

  useEffect(() => {
    console.log({ userData })
  }, [userData])

  const isRental = isLongTermRental(values)

  useEffect(() => {
    updateRentalOptions(isRental)
  }, [isRental])

  const isLoggedInRef = useRef(false)
  const isLoggedIn = isLoggedInRef.current

  const isProcessingTeamMode = (
    Boolean(new URLSearchParams(window.location.search).get("internal"))
    &&
    isLoggedIn
  )

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Assuming you have a function to set the token in your state or context
      isLoggedInRef.current = true
    }
  }, []);

  const {
    exchangeOAuthToken
  } = useExchangeOAuthToken()

  const oAuthToken = new URLSearchParams(window.location.search).get("oauth")

  useEffect(() => {
    if (oAuthToken && !isLoggedIn) {
      exchangeOAuthToken({
        variables: {
          token: oAuthToken
        }
      })
        .then(({ data }) => {
          if (data) {
            const token = data.exchangeOAuthToken
            localStorage.setItem('token', token);
            isLoggedInRef.current = true
          }
        })
    }
  }, [oAuthToken])

  /* Scrolling */
  const [selectedIndexData, setSelectedIndexData] = useState({value: 0, origin: "other"})

  const selectedIndex = +selectedIndexData.value
  const _setSelectedIndex = (value) => setSelectedIndexData({value, origin: "other"})

  const [bottomValue, _setBottomValue] = useState(0)

  const setBottomValue = (value) => {
    if (!isProcessingTeamMode) {
      _setBottomValue(value)
    } else {
      _setBottomValue(value + 38)
    }
  }

  const setSelectedIndex = (index) => {
    const currentStep = Object.keys(values).length
    if (index <= currentStep || (index === ONBOARDING_STEPS.length && currentStep === ONBOARDING_STEPS.length)) {
      _setSelectedIndex(index)
    }
  }

  const [currentStep, setCurrentStep] = useState()

  const rawState = new URLSearchParams(window.location.search).get("state")
  useEffect(() => {
    if (rawState) {
      setValue("Where is the property located?", rawState.substring(1))
    }
  }, [])

  useEffect(() => {
    setSelectedIndex(currentStep)
  }, [currentStep])

  useEffect(() => {
    setCurrentStep(Object.keys(values).length)
    console.log({ values })
  }, [values])

  /* Utility */
  const isMixedUseProperty = getIsMixedUseProperty(values)
  const isResumeShown = (selectedIndex >= ONBOARDING_STEPS.length)

  /* Application Flows */
  const [showWelcome, setShowWelcome] = useState(!rawState)

  const [isBackgroundCompleted, setIsBackgroundCompleted] = useState(false)
  const onFinish = () => setIsBackgroundCompleted(true)

  const [showPreApprovalModal, setShowPreApprovalModal] = useState(false)
  const onContinue = (userData) => { setShowPreApprovalModal(false); setUserData(userData) }

  const [selectedOption, setSelectedOption] = useState(null)

  const [borrowerData, setBorrowerData] = useState(null)

  const onSelectLoanOption = (option) => {
    setSelectedOption(option)
    trackEvent(OptionSelected(option.caption || "BALANCED OPTION"))
    console.log({ option})
    setShowTermSheet(true)
    if (isBrokerMode) {
      setShowCreateAccountBrokerModal(true)
    } else {
      setShowPreApprovalModal(true)
    }
  }

  const [showTermSheet, setShowTermSheet] = useState(false)

  useEffect(() => {
    if (isProcessingTeamMode) {
      setSelectedIndex(ONBOARDING_STEPS.length)
      setShowWelcome(false)
      if (isEqual(values, {})) {
        setValues(PROCESSING_TEAM_INITIAL_VALUES)
      }
    }
  }, [selectedIndex, isProcessingTeamMode])


  // Broker
  const [isBrokerMode, setIsBrokerMode] = useState(process.env.REACT_APP_BROKER_MODE_ENABLED ?? false)
  const [showBrokerWithUsModal, setShowBrokerWithUsModal] = useState(false)
  const toggleBrokerMode = () => { setShowWelcome(false); setIsBrokerMode(!isBrokerMode) }

  const [showCreateAccountBrokerModal, setShowCreateAccountBrokerModal] = useState(false)

  /* Markup */
  const lastStepsMarkup = (
    isMixedUseProperty
      ?
      <React.Fragment>
        <Resume
          values={values}
          setValue={setValue}
          isSelected={selectedIndex === ONBOARDING_STEPS.length}
          setBottomValue={setBottomValue}
          setSelectedIndex={setSelectedIndex}
          onFinish={() => _setSelectedIndex(ONBOARDING_STEPS.length + 1)}
          isBrokerMode={isBrokerMode}
          isProcessingTeamMode={isProcessingTeamMode}
          setBorrowerData={setBorrowerData}
        />
        <SendLoanForm
          isSelected={selectedIndex === ONBOARDING_STEPS.length + 1}
          setBottomValue={setBottomValue}
        />
      </React.Fragment>
      :
      <Resume
        values={values}
        setValue={setValue}
        isSelected={selectedIndex === ONBOARDING_STEPS.length}
        setBottomValue={setBottomValue}
        setSelectedIndex={setSelectedIndex}
        onFinish={onFinish}
        isBrokerMode={isBrokerMode}
        isProcessingTeamMode={isProcessingTeamMode}
        setBorrowerData={setBorrowerData}
      />
  )

  const isOnMixedPropertyForm = selectedIndex === ONBOARDING_STEPS.length + 1

  const backgroundMarkup = (
    <React.Fragment>
      <PageContent
        currentStep={currentStep}
        selectedIndexData={selectedIndexData}
        setSelectedIndexData={setSelectedIndexData}
        isWide={isResumeShown || isOnMixedPropertyForm || isProcessingTeamMode}
      >
        {
          !isProcessingTeamMode ?
            <QuestionaryContent
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              values={values}
              setValue={setValue}
              setBottomValue={setBottomValue}
              isBrokerMode={isBrokerMode}
            >
              {lastStepsMarkup}
            </QuestionaryContent>
            :
            lastStepsMarkup
        }
      </PageContent>
      {
        !isProcessingTeamMode ? 
          <BubbleNavigatorContainer>
            <BubbleNavigator {...{ values, selectedIndex, setSelectedIndex, quantity: ONBOARDING_STEPS.length + 1 }} />
          </BubbleNavigatorContainer>
          :
          null
      }
      {
        isProcessingTeamMode ?
          null
          :
          !isBrokerMode
            ?
            <div className='broker-button' onClick={toggleBrokerMode}>{"I'm a broker -->"}</div>
            :
            <div className='broker-button selected' onClick={toggleBrokerMode}>{"I’m a borrower -->"}</div>
      }
      {
        !isProcessingTeamMode ?
          <ResumeFooter
            values={values}
            setValue={setValue}
            setSelectedIndex={setSelectedIndex}
            isHidden={isResumeShown || isOnMixedPropertyForm}
            hideLastStep={!(selectedIndex === ONBOARDING_STEPS.length + 1)}
            isBrokerMode={isBrokerMode}
          />
          :
          null
      }
    </React.Fragment>
  );

  const builderMarkup = (
    <React.Fragment>
      <LoanBuilder values={values} setValues={setValues} setValue={setValue} onSelect={onSelectLoanOption} isBrokerMode={isBrokerMode} />
    </React.Fragment>
  )

  const createAccountBrokerModalMarkup = (
    <Modal
      className='create-account-broker-modal'
      open={showCreateAccountBrokerModal && !userData}
      centered={true}
      closable={true}
      closeIcon={() => (<div></div>)}
      footer={null}
      width={"624px"}
      onCancel={() => setShowCreateAccountBrokerModal(false)}
      maskStyle={{ backgroundColor: "rgba(60, 48, 42, 0.15)", backdropFilter: "blur(7px)" }}
      maskClosable={false}
    >
      <div>
        <CreateAccountBroker values={values} onSubmission={(userData) => { setShowCreateAccountBrokerModal(false); setUserData(userData); }} />
      </div>
    </Modal>
  )

  const preApprovalModalMarkup = (
    <Modal
      className='pre-approval'
      open={showPreApprovalModal && !userData}
      // open={true}
      centered={true}
      closable={true}
      closeIcon={() => (<div></div>)}
      footer={null}
      width={"624px"}
      onCancel={onContinue}
      maskStyle={{ backdropFilter: "blur(7px)" }}
      maskClosable={false}
    >
      <div>
      {
          isProcessingTeamMode ?
            <ProcessingTeamForm
              values={values}
              borrowerData={borrowerData}
              onContinue={onContinue}
            />
            :
            <PreApprovalForm values={values} onContinue={onContinue} />
        }
      </div>
    </Modal>
  )

  return (
    <div className={isBrokerMode ? "App broker" : "App"}>
      <PageLayout isProcessingTeamMode={isProcessingTeamMode} >
        {
          !showTermSheet
            ?
            (
              showWelcome && !isProcessingTeamMode
                ?
                <WelcomePage onContinue={() => setShowWelcome(false)} onBrokerContinue={toggleBrokerMode} />
                :
                (
                  isBackgroundCompleted && !(isMixedUseProperty && !values.price)
                    ?
                    builderMarkup
                    :
                    backgroundMarkup
                )
            )
            :
            <LoanResume
              selectedOption={selectedOption}
              values={values}
              userData={userData}
              isBrokerMode={isBrokerMode}
              onBack={() => setShowTermSheet(false)}
            />
        }
        {/* <Modal
          className='borrower-form-modal'
          open={showBrokerWithUsModal}
          centered={true}
          closable={true}
          footer={null}
          width={"532px"}
          onCancel={() => setShowBrokerWithUsModal(false)}
        >
          <div >
            <BorrowerForm
              onSubmission={
                (brokerData) => {
                  setShowBrokerWithUsModal(false);
                  setUserData(brokerData)
                  }
              }
            />
          </div>
        </Modal> */}
        {
          isBrokerMode ?
            createAccountBrokerModalMarkup
            :
            preApprovalModalMarkup
        }
      </PageLayout>
    </div>
  );
}

export default App;

/* For fast development */
const QuestionaryContent = (props) => {
  const {
    selectedIndex,
    setSelectedIndex,
    values,
    setValue,
    setBottomValue,
    children,
    isBrokerMode,
  } = props;

  useEffect(() => {
    trackSectionLoadedEvent('Background Questionary')
  }, [])

  const handleSelect = (key, value) => {
    setValue(key, value)
    trackEvent(QuestionAnsweredFromPage(key, value))
  }

  return (
    <React.Fragment>
      {
        ONBOARDING_STEPS.map(
          (step, i) => (
            <StepSection
              key={step.text}
              step={step}
              isSelected={selectedIndex === i}
              value={values[step.text]}
              onSelect={(value) => { handleSelect(step.text, value); setSelectedIndex(i + 1); }}
              setBottomValue={setBottomValue}
              isBrokerMode={isBrokerMode}
              customMarkup={i === 5 ? <div className='space'></div> : null}
            />
          )
        )
      }
      {children}
    </React.Fragment>
  );
}