import { gql, useMutation } from '@apollo/client';

const mutation = gql`
  mutation ExchangeOAuthToken($token: String!) {
    exchangeOAuthToken(token: $token)
  }
`

export const useExchangeOAuthToken = () => {
  const [exchangeOAuthToken, { data, loading, error }] = useMutation(
    mutation,
    { fetchPolicy: 'no-cache' }
  );

  return {
    exchangeOAuthToken,
    data,
    loading,
    error
  };
};