import { currencyFormatter } from "../../atoms/NumberInput/NumberInput";

const customCurrencyFormatter = (value, digits = 0) => (
  `$${currencyFormatter(value, digits).replace(/\$/g, '')}`
)

/**
 * Instances of this class format all the values collected
 * before they are used in the loan resume and in the term sheet.
 */
export class TermSheet {
  /* Class Utils */
  static isRentalLoan(loanType) {
    return loanType === "Rental";
  }

  static constructForBridgeLoan(input) {
    return new TermSheet(
      input.address,
      "Bridge",
      input.propertyType,
      input.creditScore,
      input.investmentExperience,
      input.price,
      input.currentLoanBalance,
      input.cashoutAmount,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      input.ltv,
      null,
      input.rate,
      input.totalLoanAmount,
      input.buydownPoints
    )
  }

  static constructForFixAndFlipLoan(input) {
    return new TermSheet(
      input.address,
      "FixAndFlip",
      input.propertyType,
      input.creditScore,
      input.investmentExperience,
      input.price,
      input.currentLoanBalance,
      input.cashoutAmount,
      input.rehabBudget,
      input.arv,
      null,
      null,
      null,
      null,
      null,
      input.ltv,
      input.ltc,
      input.rate,
      input.totalLoanAmount,
      input.buydownPoints
    )
  }

  static constructForRentalLoan(input) {
    return new TermSheet(
      input.address,
      "Rental",
      input.propertyType,
      input.creditScore,
      null,
      input.price,
      input.currentLoanBalance,
      input.cashoutAmount,
      null,
      null,
      input.monthlyRent,
      input.annualTaxes,
      input.annualInsurance,
      input.annualHOA,
      input.dscr,
      input.ltv,
      null,
      input.rate,
      input.totalLoanAmount,
      input.buydownPoints
    )
  }

  /* Class Constants */
  HOLDBACK_PERCENTAGE = 0.1;
  ORIGINATION_PERCENTAGE = 0.02;
  SERVICE_FEE = 995;
  EST_LEGAL_COSTS = 1250;

  /* Instance */
  constructor(
    address,
    loanType,
    propertyType,
    creditScore,
    investmentExperience,
    price,
    currentLoanBalance,
    cashoutAmount,
    rehabBudget,
    arv,
    monthlyRent,
    annualTaxes,
    annualInsurance,
    annualHOA,
    dscr,
    ltv,
    ltc,
    rate,
    totalLoanAmount,
    buydownPoints
  ) {
    this.address = address;
    this._loanType = loanType;
    this._propertyType = propertyType;
    this._creditScore = creditScore;
    this._investmentExperience = investmentExperience;
    this._price = price;
    this._currentLoanBalance = currentLoanBalance;
    this._cashoutAmount = cashoutAmount;
    this._rehabBudget = rehabBudget;
    this._monthlyRent = monthlyRent;
    this._annualTaxes = annualTaxes;
    this._annualInsurance = annualInsurance;
    this._annualHOA = annualHOA;
    this._dscr = dscr;
    this._arv = arv;
    this._ltv = ltv;
    this._ltc = ltc;
    this._rate = rate;
    this._totalLoanAmount = Number(totalLoanAmount);
    this._buydownPoints = buydownPoints;
  }

  /* Instance Utils */
  get isRentalLoan() {
    return this.constructor.isRentalLoan(this._loanType)
  }

  get isRefinance() {
    return this._currentLoanBalance >= 0
  }

  /* Section General Info */
  get totalLoanAmount() {
    return customCurrencyFormatter(this._totalLoanAmount + this._rehabBudget, 0)
  }
  /**
   * For refinance loans
   * 
   * If this value is negative, it means it's a cashout loan
   * as we need money to close the loan
   */
  get closingCashAmount() {
    const feesAmount = this.buydownFeeAmount + this.originationFeeAmount + this.SERVICE_FEE + this.EST_LEGAL_COSTS + this.rentalFeeAmount;
    if (this.isRefinance) {
      return (this._currentLoanBalance + feesAmount) - (this._totalLoanAmount - this._rehabBudget)
    } else {
      return this.downPaymentAmount + feesAmount
    }
  }

  get closingCash() {
    return customCurrencyFormatter(this.closingCashAmount, 0)
  }

  get originationFeeAmount() {
    return this._totalLoanAmount * this.ORIGINATION_PERCENTAGE
  }

  get originationFee() {
    return customCurrencyFormatter(this.originationFeeAmount, 0);
  }
  
  get originationFeePercentage() {
    return `${this.ORIGINATION_PERCENTAGE * 100}%`;
  }

  get serviceFee() {
    const amount = this.SERVICE_FEE
    return customCurrencyFormatter(amount, 0)
  }
  
  get buydownFeeAmount() {
    return this._totalLoanAmount * (this._buydownPoints / 100)
  }

  get buydownFee() {
    return customCurrencyFormatter(this.buydownFeeAmount, 0)
  }

  get rentalFeeAmount() {
    let rentalFee = 0
    if (this.isRentalLoan) {
      rentalFee = (this._annualInsurance / 2) + (this._annualTaxes / 2)
    }
    return rentalFee
  }

  get rentalFee() {
    return customCurrencyFormatter(this.rentalFeeAmount, 0)
  }

  get downPaymentAmount() {
    if (this.isRefinance) {
      return 0
    } else {
      return this._price - (this._totalLoanAmount - (this._rehabBudget || 0))
    }
  }
  
  get downPayment() {
    return customCurrencyFormatter(this.downPaymentAmount, 0)
  }

  get proRatedInterest() {
    const perDiemRate = (this._rate) / 365
    const perDiemInterest = perDiemRate * this._totalLoanAmount
    return customCurrencyFormatter(perDiemInterest, 0)
  }

  get thirdPartyCosts() {
    return "Contact closing agent"
  }

  get legalCosts() {
    return customCurrencyFormatter(this.EST_LEGAL_COSTS, 0)
  }

  /* Section Additional Details */
  get loanType() {
    if (this.isRentalLoan) {
      return "Rental"
    } else {
      if (this._loanType === "Bridge") {
        return "Bridge"
      } else {
        return "Fix & Flip"
      }
    }
  }

  get amortization() {
    if (this.isRentalLoan) {
      return "30 year"
    } else {
      return "Interest Only"
    }
  }

  get lien() {
    return "1st position"
  }

  get asIsValue() {
    return customCurrencyFormatter(this._price)
  }

  get estimatedARV() {
    if (!this._arv) {
      return "N/A"
    }
    return customCurrencyFormatter(this._arv)
  }

  get holdbackAmount() {
    const amount = (this._currentLoanBalance || 0) * this.HOLDBACK_PERCENTAGE
    return customCurrencyFormatter(amount, 0)
  }

  /* PDF Elements */
  get advanceAmount() {
    return customCurrencyFormatter(this._totalLoanAmount - this._rehabBudget)
  }

  get ltv() {
    return `${(this._ltv * 100).toFixed(2)}%`
  }

  get constructionReserve() {
    if (!this._rehabBudget) {
      return "N/A"
    }

    return customCurrencyFormatter(this._rehabBudget)
  }

  get creditScore() {
    return this._creditScore
  }

  get investmentExperience() {
    return this._investmentExperience
  }

  get propertyType() {
    return this._propertyType
  }
  
  get loanPurpose() {
    if (this.isRefinance) {
      if (this.closingCashAmount < 0) {
        return "Cashout Refi"
      } else {
        return "NoCashout Refi"
      }
    } else {
      return "Purchase"
    }
  }

  get ltc() {
    if (!this._ltc) {
      return "N/A"
    }

    return `${Number(this._ltc * 100).toFixed(2)}%`
  }

  get interestRate() {
    return `${Number(this._rate).toFixed(2)}%`
  }

  get term() {
    return this.isRentalLoan ? "30 Years" : "12 Months"
  }

  get buydownPercentage() {
    return `${this._buydownPoints}%`
  }

  /* Rental */
  get stimatedMonthlyRent() {
    const amount = this._monthlyRent
    return customCurrencyFormatter(amount)
  }

  get annualExpenses() {
    const amount = this._annualTaxes + this._annualInsurance + this._annualHOA
    return customCurrencyFormatter(amount)
  }

  get dscr() {
    return this._dscr
  }
}