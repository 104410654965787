import React from 'react';
import './TopBar.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { SignInButton } from '../SignInButton/SignInButton';

export const TopBar = (props) => {
  const { } = props;
  return (
    <div className='top-bar' onClick={() => { window.location.href = window.location.origin; }}>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/constlending-logo.svg"} />
      </div>
      <SignInButton />
    </div>
  );
}
