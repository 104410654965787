import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BE_URL}/graphql`,
  cache: new InMemoryCache(),
});

export const ApolloAppProvider = ({ children }) => (
  <ApolloProvider client={client}>
    { children }
  </ApolloProvider>
) 